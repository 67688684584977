import React from 'react';
import Layout from 'components/Layout';
import { StaticQuery, graphql } from 'gatsby';
// import { Link } from 'gatsby';
// import { Link } from 'gatsby';
import get from 'lodash/get';
import Helmet from 'react-helmet';
import SEO from 'components/SEO/SEO';
import querystring from 'querystring';
import logger from 'utils/logger';
import { Sticky } from 'react-sticky';

import { Container, Row, Col } from 'reactstrap';
import { Fluid, Offset } from 'components/Containers';

import LoadingView from 'components/Loaders/LoadingView';
import SimpleHeader from 'components/Headers/SimpleHeader';
import NavCategories from 'components/Navigation/NavCategories';
// import MediaFilters from 'components/Navigation/MediaFilters';
import ArticleEntry from 'components/Articles/ArticleEntry';

// import DataArticles from 'utils/articlesStaticData';
// import InfiniteScroll from 'react-infinite-scroller';

class Template extends React.Component {
  componentDidMount () {

  }
  // loadArticles (){
  //   var items = list.DataArticles(0, size).map(i => {
  //     return <myview item={i} key={i.id} />
  //   }
  // }
  render () {
    logger.template(__filename, '>', 'render()');
    const siteTitle = get(this, 'props.data.site.siteMetadata.title');
    const data = get(this, 'props.data');
    logger.gql(data);
    if (
      !data ||
      !data.allContentfulArticle ||
      !data.allContentfulCategory
    ) { return <LoadingView />; }

    let mediaData = data.allContentfulArticle.edges;
    const categoriesData = data.contentfulMenu.fields.links;

    // QueryString Filters
    let pageQuery;
    if (typeof window !== 'undefined') {
      logger.group(`QueryString Filters`);
      pageQuery = querystring.parse(window.location.search.substring(1));

      Object.keys(pageQuery).forEach((key) => {
        logger.verbose(key);
        if (pageQuery[key] === 'all' || pageQuery[key] === 'ALL' || pageQuery[key] === 'All') {
          pageQuery[key] = null;
        }
      });

      logger.info('QueryString Filters pageQuery:', pageQuery);
      // Filter by series
      if (pageQuery.category) {
        if (
          pageQuery.category !== 'all' || pageQuery.category !== 'ALL' || pageQuery.category !== 'All'
        ) {
          logger.group(`Filter by category`);
          logger.info('QueryString Filters pageQuery.category', pageQuery.category);

          mediaData = mediaData.filter((article) => {
            let include;
            if (article.node.categories) {
              article.node.categories.forEach((category) => {
                include = category.slug === pageQuery.category;
              });
            }
            return include;
          });

          logger.info('QueryString Filters mediaData', mediaData);
          logger.groupEnd();
        }
      }
      logger.groupEnd();
    }

    logger.templateEnd();
    return (
      <Layout>
        <Fluid>
          <SEO
            title={`Media`}
            pathname={`/media/articles`}
          />
          <SimpleHeader title={`Media`} />
          <Sticky topOffset={49 + 144}>
            {({ style }) => (
              <header className={`z-300`} style={style}>
                <NavCategories categories={categoriesData} allButton={`All`} className={`bg-indigo-700`} />
              </header>
            )}
          </Sticky>
          <section className={`articles`}>
            <Container fluid className={`prl-0`}>
              {/* <InfiniteScroll
                pageStart={0}
                loadMore={loadFunc}
                hasMore={true || false}
                loader={<div className="loader" key={0}>Loading ...</div>}
              >
                { mediaData && mediaData.map((article) => {
                  return (
                    <ArticleEntry key={`article-${article.slug}`} {...article} />
                  );
                })}
              </InfiniteScroll>
            */}
              { mediaData &&
                mediaData.length > 0 ? (
                  mediaData.map((edge) => {
                    const article = edge.node;
                    return (
                      <ArticleEntry key={`article-${article.id}`} {...article} />
                    );
                  })
                ) : (
                  <Fluid>
                    <Offset>
                      <Row>
                        <Col className={`ptb-30em`}>
                          <h2 className={`ls--30em`}>No articles found in this category.</h2>
                        </Col>
                      </Row>
                    </Offset>
                  </Fluid>
                )
              }
            </Container>
          </section>
        </Fluid>
      </Layout>
    );
  }
}
export const query = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulArticle(sort: { fields: [createdAtLegacy], order: DESC}){
      edges{
        node {
          id
          ... Article
        }
      }
    }
    contentfulMenu(location: {eq: "Media"}){
      name
      location
      fields{
        links{
          id
          key
          name
          slug
          contentTypes
        }
      }
    }
    allContentfulCategory( filter: { contentTypes: { in: ["articles"] } } ){
      edges{
        node {
          id
          ... Category
        }
      }
    }
  }
`;

export default Template;

// export default ({ children }) => (
//   <StaticQuery
//     query={query}
//     render={data => (
//       <>
//         <Helmet title={`${`Media Articles`}`} />
//         <Template data={data}>
//           {children}
//         </Template>
//       </>
//     )}
//   />
// );

