import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
// import { Navbar } from 'reactstrap';
import { Nav, NavItem } from 'reactstrap';
import PerfectScrollbar from 'react-perfect-scrollbar';
// import ReactSVG from 'react-svg';
// import { Link } from 'gatsby';
import { navigate } from 'gatsby';
import querystring from 'querystring';
import { Fluid, Offset } from 'components/Containers';
import logger from 'utils/logger';

export default class NavCategories extends React.Component {
  handleQueryString (params, scroll = 0) {
    logger.group(
      __filename,
      '>',
      `handleQueryString (${JSON.stringify(params)})`
    );
    logger.debug('handleQueryString this:', this);
    if (typeof window !== 'undefined') {
      logger.verbose('handleQueryString params:', params);
      const pageQuery = querystring.parse(window.location.search.substring(1));
      logger.verbose('handleQueryString pageQuery:', pageQuery);

      Object.keys(params).forEach((key) => {
        logger.verbose(key, params[key]);
        pageQuery[key] = params[key];
      });

      logger.verbose('handleQueryString pageQuery update:', pageQuery);

      const locationQuery = this.props.navigate ? this.props.navigate : window.location.pathname.substring(0);
      const queryUpdate = `${locationQuery}?${querystring.stringify(pageQuery)}`;
      logger.verbose('handleQueryString queryUpdate:', queryUpdate);
      navigate(queryUpdate);
      window.scrollTo({
        top: scroll,
        behavior: 'smooth'
      });
    }
    logger.groupEnd();
  }
  render () {
    const propClasses = this.props.className;
    const classes = classNames(
      'navbar-categories',
      {
        otherCategories: this.props.otherCategories > 0
      },
      ...propClasses
    );
    let pageQuery = {};
    if (typeof window !== 'undefined') {
      pageQuery = querystring.parse(window.location.search.substring(1));
    }
    const globalKey = this.props.forceKey ? this.props.forceKey : 'category';
    const scroll = this.props.scrollOffset ? this.props.scrollOffset : 0;
    const otherCategories = this.props.otherCategories ? this.props.otherCategories : [];
    // logger.verbose(`pageQuery`, pageQuery);
    let othersClass = `mains`;
    if (otherCategories.length > 0) {
      othersClass = !otherCategories.includes(pageQuery.category) ? `others` : ``;
    }
    // logger.verbose(`othersClass`, othersClass);

    return (
      <div className={classes}>
        <PerfectScrollbar className={`ps-hide-y`}>
          <Nav
            className={`nav-categories fs-08em prl-10em ${otherCategories && (otherCategories.length > 0 && `otherCategories ${pageQuery.category}`)} ${othersClass}`
            }
          >
            <Fluid>
              <Offset>
                {this.props.label && (
                  <span
                    className={`color-indigo-300 fs-11em mr-15em pos-relative top-1px`}
                  >
                    {this.props.otherCategoriesLabel && (<span className="d-none d-lg-inline">Other </span>)}
                    {this.props.label}:{` `}
                  </span>
                )}
                {this.props.allButton && (
                  <NavItem>
                    <button
                      key={`filter-productsLine-all`}
                      onClick={(e) => {
                        this.handleQueryString({ [globalKey]: 'all' });
                      }}
                      alt={`All Product Lines`}
                      className={`btn btn-link ptb-0 pl-0 inline tt-none fs-11em ls-10em color-white-hover${
                        !pageQuery[globalKey] || pageQuery[globalKey] === 'all'
                          ? ` color-indigo-100`
                          : ` active color-indigo-300`
                      }`}
                    >
                      {this.props.allButton}
                    </button>
                  </NavItem>
                )}
                {this.props.categories &&
                  this.props.categories.map((filter) => {
                    // logger.debug(`this.props.categories.map((filter) => `, filter);
                    const filterQuery = {};
                    const key = this.props.forceKey
                      ? this.props.forceKey
                      : filter.key;
                    filterQuery[key] = filter.slug;
                    // logger.debug(`otherCategories.includes(filter.slug)`, !otherCategories.includes(filter.slug), filter.slug);
                    return (
                      filter.name && filter.slug ? (
                        <NavItem key={`filter-${filter.id}=${filter.slug}`} className={`topCategory ${filter.slug}`}>
                          <button
                            onClick={(e) => {
                              this.handleQueryString(filterQuery, scroll);
                            }}
                            alt={filter.name}
                            className={`btn btn-link ptb-0 pl-0 inline tt-none fs-11em ls-10em color-white-hover${
                              pageQuery[filter.key] === filter.slug
                                ? ` active color-indigo-100`
                                : ` color-indigo-300`
                            }`}
                          >
                            {filter.name}
                          </button>
                        </NavItem>
                      ) : ('')
                    );
                  })}
                {otherCategories && (
                  otherCategories.length > 0 && otherCategories.map((filter) => {
                    const filterQuery = {};
                    const key = this.props.forceKey
                      ? this.props.forceKey
                      : filter.key;
                    filterQuery[key] = filter.slug;
                    return (
                      <NavItem key={`filter-${filter.id}=${filter.slug}`} className={`otherCatrgory ${filter.slug}`}>
                        <button
                          onClick={(e) => {
                            this.handleQueryString(filterQuery, scroll);
                          }}
                          alt={filter.name}
                          className={`btn btn-link ptb-0 pl-0 inline tt-none fs-11em ls-10em color-white-hover${
                            pageQuery[filter.key] === filter.slug
                              ? ` active color-indigo-100`
                              : ` color-indigo-300`
                          }`}
                        >
                          {filter.name}
                        </button>
                      </NavItem>
                    );
                  })
                )}

                {this.props.children && this.props.children}
              </Offset>
            </Fluid>
          </Nav>
        </PerfectScrollbar>
      </div>
    );
  }
}

NavCategories.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  categories: PropTypes.arrayOf(PropTypes.shape({
    slug: PropTypes.string,
    name: PropTypes.string
  })).isRequired,
  otherCategories: PropTypes.arrayOf(PropTypes.string),
  className: PropTypes.string,
  // btnClassName: PropTypes.string,
  forceKey: PropTypes.string,
  scrollOffset: PropTypes.string,
  label: PropTypes.string,
  allButton: PropTypes.string
};
NavCategories.defaultProps = {
  children: null,
  className: '',
  otherCategories: [],
  // btnClassName: '',
  forceKey: '',
  scrollOffset: '',
  label: '',
  allButton: ''
};
