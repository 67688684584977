import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Row, Col, Container } from 'reactstrap';
// import moment from 'moment';
import { Link } from 'gatsby';
import Img from 'components/Elements/Img';

export default class CardNews extends React.Component {
  render () {
    const article = this.props;
    const propClasses = article.className;
    const classes = classNames(
      'article-loop',
      'article',
      ...propClasses
    );
    const bgDefault = article.thumbnail ? `white` : `indigo-900`;

    let excerpt = ``;
    if (article.excerpt) {
      if (article.excerpt.excerpt) {
        excerpt = article.excerpt.excerpt;
      }
    } else if (article.content) {
      if (article.content.childMarkdownRemark.excerpt) {
        excerpt = article.content.childMarkdownRemark.excerpt;
      }
    }
    return (
      <article className={classes}>
        <Container fluid>
          <Row>
            <Col md={5} lg={4} className={`prl-0 thumbnail thumbnail-article ${!article.color && `bg-${bgDefault}`}`} style={{ height: `100%`, background: article.color, borderRight: `1px solid #f1f0f7` }}>
              { article.banner && (
                <Img
                  title={article.title}
                  alt={article.title}
                  fixed={article.banner.fluid}
                  style={{
                    position: `absolute`,
                    top: `0`,
                    left: `0`,
                    height: `100%`,
                    width: `100%`
                  }}
                  imgStyle={{
                    top: `50%`,
                  }}
                  objFit={`cover`}
                  fadeIn
                />
              )}
              { !article.banner && article.thumbnail ? (
                <Img
                  title={article.title}
                  alt={article.title}
                  fluid={article.thumbnail.fluid}
                  style={{
                    position: `absolute`,
                    top: `0`,
                    left: `0`,
                    height: `100%`,
                    width: `100%`
                  }}
                  imgStyle={{
                    top: `50%`,
                    transform: `translate3D(-50%,-50%,0)`,
                    left: `50%`,
                    maxWidth: `60%`,
                  }}
                  objFit={`contain`}
                  fadeIn
                />
              ) : (
                article.logo ? (
                  <Img
                    title={article.title}
                    alt={article.title}
                    fixed={article.logo.fixed}
                    style={{
                      position: `absolute`,
                      top: `0`,
                      left: `0`,
                      height: `100%`,
                      width: `100%`
                    }}
                    imgStyle={{
                      top: `50%`,
                      left: `50%`,
                      transform: `translate3D(-50%,-50%,0)`,
                      maxHeight: `180px`,
                      maxWidth: `280px`
                    }}
                    objFit={`contain`}
                    fadeIn
                  />
                ) : (
                  <div style={{
                    background: `url('https://images.ctfassets.net/vd4y94r5n5vv/Qbh2RBEeys2g6mukCIoES/7ad7dbc8a8ba97472cc7e77e73a946f5/articles-default-image.png?h=250')`,
                    backgroundPosition: `center`,
                    backgroundSize: `cover`,
                    display: `block`,
                    position: `absolute`,
                    top: `0`,
                    right: `0`,
                    bottom: `0`,
                    left: `0`,
                  }} />
                )
              )}
            </Col>
            <Col md={7} lg={8}>
              <div className="article-content">
                <h1 className={`ff-base lh-13 fw-500 ls-10em mb-10em`}>
                  <Link to={article.slug ? `/media/articles/${article.categories ? article.categories[0].slug : `c`}/${article.slug}` : `/media`} className={`color-gray-cold-700 color-black-hover`}>
                    {article.title}
                  </Link>
                </h1>
                {excerpt && <summary itemProp="description"><p className={`fs-095em mb-15em`}>{excerpt}</p></summary>}
                <div className="articleMeta">
                  <i className="icon icon-Calendar-3 mr-10em" /><time>{article.createdAtLegacy ? article.createdAtLegacy : article.createdAt}</time>
                  {
                    article.categories && (
                      article.categories.length > 0 && article.categories.map((item) => {
                        return (
                          <span key={`categories-${item.slug}`}>
                            <span className="separator mrl-10em alpha-30">/</span>
                            <Link
                              to={item.slug ? `/media?category=${item.slug}` : '/media'}
                              className={`category color-gray-cold-400 category-${item.slug}`}
                            >
                              {item.name}
                            </Link>
                          </span>
                        );
                      })
                    )
                  }
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </article>
    );
  }
}
CardNews.propTypes = {
  category: PropTypes.string.isRequired,
  categorySlug: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  // thumbnail: PropTypes.string.isRequired,
  // excerpt: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  className: PropTypes.string
};
CardNews.defaultProps = {
  className: ''
};
